@import 'src/styles';

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;

  @media (min-width: $desktopWidth) {
    padding-bottom: 50px;
  }

  .title {
    font-size: $fontSize26;
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    font-weight: $fwstb;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  .content {
    overflow: hidden;

    .feedback {
      height: 180px;
      padding: 20px 15px;
    }

    @media (min-width: $desktopWidth) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
      width: 100%;

      .feedback {
        width: 46%;
      }
    }

    &.hidden {
      max-height: 590px;

      @media (min-width: $desktopWidth) {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        max-height: 390px;
      }
    }
  }
}