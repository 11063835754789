@import "src/styles";

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 48%;
  padding: 25px 15px;
  border-radius: 15px;
  background-color: #1E3B48;
  box-shadow: none;
  margin-top: 15px;
  position: relative;

  &:nth-child(odd) {
    margin-right: 15px;
  }

  @media (max-width: 410px) {
    &:nth-child(odd) {
      margin-right: 13px;
    }
  }

  @media (max-width: 360px) {
    &:nth-child(odd) {
      margin-right: 11px;
    }
  }

  @media (min-width: $desktopWidth) {
    width: 22%;

    &:nth-child(odd) {
      margin-right: 0;
    }

  }

  .arrow {
    position: absolute;
    top: 10px;
    right: 5px;
    width: 24px;
    height: 24px;
  }

  img {
    width: 65px;
    height: 65px;
  }

  .title {
    font-size: $fontSize18;
    font-weight: $fwstb;
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    text-align: center;
    padding: 15px 15px 0;
    line-height: $fontSize22;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize22;
    }
  }
}