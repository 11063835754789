@import "src/styles";

.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;

  @media (min-width: $desktopWidth) {
    padding-bottom: 50px;
  }

  .title {
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize26;
    font-weight: $fwstb;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  .items {
    @media (min-width: $desktopWidth) {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: wrap;
    }

    .link {
      color: #14D5C2;
      font-weight: $fwstb;
      font-size: $fontSize14;
      @media (min-width: $desktopWidth) {
        font-size: $fontSize17;
      }
    }
  }
}