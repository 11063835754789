@import 'src/styles';

.wrapperSpinner {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 100000;
  top: 0;
  right: 0;
  background: $preloaderBg;

  & .spinner {
    position: absolute;
    width: 50px;
    height: 50px;
    @include animation('preloader 1s linear infinite');

    svg circle{
      fill: none;
      stroke: $preloaderColor;
    }
  }
}

@include keyframes(preloader) {
  0% { transform: rotate(0); }
  100% { transform: rotate(360deg); }
}