@import 'src/styles';

.helper {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  background-color: $mainBgColor;
  overflow-y: auto;
  z-index: 1;
  transform: translateY(100%);
  transition: 0.2s opacity ease-in-out;
  max-height: 150px;

  &__button {
    width: 100%;
  }

  &_item {
    width: 100%;
    outline: none;
    display: flex;
    font-size: $fontSize14;
    line-height: 24px;
    color: #ee8f61;
    border-top: 1px solid #797979;

    button {
      padding: 10px 15px;
      border: 0;
      background: none;
      text-align: left;
    }
  }
}
