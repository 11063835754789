@import "src/styles";

.tile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  background-color: #2D5668;
  height: 120px;
  width: 105px;
  border-radius: 12px;
  padding: 10px 8px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.20);
  margin-top: 30px;

  .image {
    width: 60px;
    height: 60px;
  }

  &.active {
    border: 1.5px solid #D0B894;
    background: #1E3B48;
  }

  @media (min-width: $desktopWidth) {
    height: 160px;
    width: 140px;
  }

  .zodiac_name {
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize17;
    font-weight: $fwstb;
    line-height: $fontSize20;
    padding: 5px 0 2px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize22;
    }
  }

  .date {
    color: rgba(208, 184, 148, 0.80);
    font-size: $fontSize10;
    font-weight: $fwb;
    line-height: $fontSize20;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize14;
    }
  }
}