@import "src/styles";

.wrapper {

  .title {
    font-size: $fontSize26;
    font-weight: $fwstb;
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    padding-bottom: 5px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  .tiles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    @media (min-width: $desktopWidth) {
      justify-content: space-evenly;
    }

  }
}