@import 'src/styles';

.results {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 315px;
  margin-bottom: 20px;

  .middle_block {
    display: flex;
    flex-direction: column;
    align-items: center;

    .img {
      width: 81px;
      height: 15px;
    }

    .percent {
      font-family: $fontFamilyPhilosopher;
      font-weight: $fwstb;
      font-size: $fontSize22;
      line-height: $fontSize26;
      color: #faf6e8;
      filter: blur(4px);
    }
  }
}

.overlay {
  background-color: #2C3651;
  border-radius: 15px;
  width: 343px;
  position: relative;
  padding: 10px 20px;

  @media (min-width: $desktopWidth) {
    width: 600px;
  }

  .waves_bg {
    background-position: top right;
    position: absolute;
    top: 0px;
    right: 0;
    background-repeat: no-repeat;
    background-image: image-set("./images/bg.webp" 1x, "./images/bg@2x.webp" 2x, "./images/bg@3x.webp" 3x);
    width: 100%;
    height: 100%;
  }

  .title {
    color: #FAF6E8;
    opacity: 0.8;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize24;
    font-weight: $fwstb;
    line-height: $fontSize30;
    margin-bottom: 10px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize28;
    }
  }

  .text {
    color: rgba(255, 255, 255, 0.80);
    font-size: $fontSize15;
    line-height: $fontSize22;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize18;
    }
  }
}