@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;

  .title {
    font-size: $fontSize26;
    font-weight: $fwstb;
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    margin-bottom: 20px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  .swiper_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 110%;

    .plus {
      margin: -5px 0 10px;
    }
  }

  .sub_button {
    margin-top: 0;
  }
}