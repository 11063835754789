@import "src/styles";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: $desktopWidth) {
    padding-bottom: 50px;
  }


  .title {
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize26;
    font-weight: $fwstb;
    padding-bottom: 5px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }
}