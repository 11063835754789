@import "src/styles";

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  padding-top: 0;

  @media (min-width: $desktopWidth) {
    padding-bottom: 20px;
  }

  main,
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $desktopWidth) {
      flex-direction: row-reverse;
    }

    img {
      width: 375px;
      height: 240px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: $desktopWidth) {
        margin-top: 20px;
      }

      .sub_button {
        @media (min-width: $desktopWidth) {
          width: 50%;
        }
      }
    }
  }

  .title {
    font-family: 'Philosopher';
    font-weight: $fwstb;
    font-size: $fontSize28;
    white-space: break-spaces;
    padding: 15px;
    color: #F2E6C4;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize32;
    }
  }

  .subtitle {
    font-family: 'Open Sans';
    color: $white;
    opacity: 70%;
    font-size: $fontSize15;
    line-height: $fontSize22;
    text-align: center;
    padding: 0 15px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize20;
      line-height: $fontSize24;
    }
  }
}