@import 'src/styles/index';

.block {
  width: 100%;
  padding: 10px 10px;
  margin: 5px 0;
  box-shadow: none;

  .title {
    position: relative;
    width: 100%;
    text-align: left;
    font-size: $fontSize18;
    font-family: $fontFamilyPhilosopher;
    font-weight: $fwstb;
    color: #FAF6E8;
    padding: 0 20px 0 5px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize24;
    }

    &::before {
      content: '';
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -10px;
      background-color: #2D5668;
    }

    &::after {
      content: '';
      transform: rotateZ(-180deg);
      transition: transform 200ms;
      display: block;
      position: absolute;
      top: 2px;
      right: 0;
      width: 20px;
      height: 20px;
      background: url(icons/more.svg) no-repeat center center;
    }
  }

  .content {
    width: 100%;
    transition: max-height 200ms, margin 100ms;
    overflow: hidden;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.collapsed {
    .content {
      max-height: 0;
      margin: 0;
    }

    .title {
      &::after {
        transform: rotateZ(0deg);
      }
    }
  }
}