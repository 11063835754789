@import 'src/styles';

.insight {
  width: 100%;
  padding: 15px 5px 0;
  display: flex;
  align-items: flex-start;

  .dot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .blur {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: linear-gradient(0deg, #1CF3DD, #1CF3DD), linear-gradient(0deg, #19E6D0, #19E6D0);
      filter: blur(5px);
      position: absolute;
      top: 10px;
    }

    .dot_mini {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: linear-gradient(0deg, #1CF3DD, #1CF3DD), linear-gradient(0deg, #19E6D0, #19E6D0);
      filter: blur(1px);
      position: absolute;
      top: 11px
    }
  }

  .text {
    font-size: $fontSize15;
    line-height: $fontSize22;
    color: $white;
    opacity: 90%;
    padding-left: 20px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize18;
      line-height: $fontSize26;
    }
  }
}