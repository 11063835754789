@import 'src/styles';

$errorBorderColor: rgba(255, 0, 0, 0.8);

@mixin field {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  border: 1.5px solid #14D5C2;
  background: rgba(36, 117, 131, 0.10);
  padding-left: 10px;
  color: $white;
  font-size: $fontSize15;

  @media (min-width: $desktopWidth) {
    font-size: $fontSize18;
  }

  &.field_error {
    border-color: $errorBorderColor
  }
}

form {
  width: 100%;

  @media (min-width: $desktopWidth) {
    max-width: 500px;
  }

  .field_wrap {
    margin-bottom: 15px;

    .date_fields {
      display: flex;
      justify-content: space-between;
    }

    .time_fields {
      display: flex;
      justify-content: flex-start;
    }

    .name {
      color: #247583;
      font-size: $fontSize15;
      font-weight: $fwb;
      padding-left: 10px;
      padding-bottom: 8px;

      @media (min-width: $desktopWidth) {
        font-size: $fontSize18;
      }
    }

    .place_field {
      @include field;
      background: rgba(36, 117, 131, 0.10);
      border: 0;
      color: $white;
      border: 1.5px solid #14D5C2;
    }

    .date_field {
      @include field;
      width: 33%;

      &:nth-child(2) {
        margin: 0 8px;
      }
    }

    .time_field {
      @include field;
      width: 32%;

      &:nth-child(2) {
        margin: 0 8px;
      }
    }
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}