@import 'src/styles';

.container {
  width: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $desktopWidth) {
    padding-bottom: 50px;
  }

  .title {
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize26;
    font-weight: $fwstb;
    margin-bottom: 20px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (min-width: $desktopWidth) {
      flex-direction: row-reverse;
    }

    img {
      width: 345px;
      height: 417px;
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      @media (min-width: $desktopWidth) {
        width: 50%;
        margin-top: 50px;
      }

      .sub_button {
        @media (min-width: $desktopWidth) {
          width: 60%;
        }
      }
    }
  }
}