@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;
  position: relative;

  .title {
    font-size: $fontSize26;
    font-weight: $fwstb;
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    margin-bottom: 20px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  iframe {
    width: 100%;
    height: 700px;
    border: 0;
    max-width: 600px;
  }

  .overlay {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: absolute;
    bottom: 0px;
    opacity: 0;
    transition: 3s all ease;
    pointer-events: none;

    &.show {
      opacity: 1;
      pointer-events: auto;
    }
  }
}