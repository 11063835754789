@import 'src/styles';

.showMore {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;

}

.showMoreText {
  color: $mineShaft;
  font-size: $fontSize17;
  font-weight: $fwb;
  padding-right: 23px;
  position: relative;
  color: #14D5C2;

  &::after {
    content: '';
    position: absolute;
    top: 60%;
    transform: translate(0, -50%);
    right: 0;
    width: 23px;
    height: 23px;
    background-image: url("./arrow.svg");

  }

  &.open::after {
    transform: rotate(-180deg) translate(0, 50%);
  }
}