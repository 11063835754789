@import 'src/styles';

.wrapper {
  position: relative;
  width: 100%;
  height: 55px;
}

.input {
  @include input-style;
  border-color: $primaryColor;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  border-radius: 10px;
  background-color: $white;
  font-size: $fontSize16;
  color: $gray;

  &__invalid {
    border-color: $colorError;
  }
}
