@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;

  .title {
    font-size: $fontSize26;
    font-weight: $fwstb;
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    margin-bottom: 20px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }
  iframe{
    width: 100%;
    height: 550px;
    border: 0;
  }

  .overlay {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    position: relative;
    bottom: 0px;
    opacity: 1;
    transition: 3s all ease;
    pointer-events: none;
    
    .other_position {
      top: 430px;
      height: 120px;
    }

  }
}