@import "src/styles";

.tile {
  background-color: #1E3B48;
  box-shadow: none;
  width: 100%;
  margin: 0 auto 15px;
  padding-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: $desktopWidth) {
    width: 30%;
  }

  .top_part {
    display: flex;
    align-items: center;

    .image {
      width: 60px;
      height: 60px;
      margin-right: 15px;
    }

    .top_rigth_part {
      display: flex;
      flex-direction: column;


      .zodiac_name {
        color: #FAF6E8;
        font-family: $fontFamilyPhilosopher;
        font-size: $fontSize20;
        font-size: $fwstb;
        margin-bottom: 10px;

        @media (min-width: $desktopWidth) {
          font-size: $fontSize24;
        }
      }

      .date {
        color: #D0B894;
        font-size: $fontSize14;
        font-weight: $fwsb;

        @media (min-width: $desktopWidth) {
          font-size: $fontSize18;
        }
      }
    }
  }

  .text {
    color: $white;
    opacity: 0.7;
    font-size: $fontSize15;
    margin-top: 10px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  
    @media (min-width: $desktopWidth) {
      font-size: $fontSize18;
    }
  }

  .link {
    align-self: flex-end;
    padding: 10px 20px 0;
    color: #14D5C2;
    font-weight: $fwstb;

    &::after {
      content: '';
      transform: rotate(-90deg);
      background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0' fill='%23D8D8D8' d='M19 0v19H0V0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m9.821 12.38 4.046-3.675a.387.387 0 0 0 0-.584.486.486 0 0 0-.642 0L9.5 11.505 5.775 8.12a.487.487 0 0 0-.642 0 .388.388 0 0 0 0 .585l4.046 3.675a.492.492 0 0 0 .642 0z' fill='%232D2D2D' stroke='%2314D5C2' stroke-width='.88'/%3E%3C/svg%3E%0A");
      width: 20px;
      height: 20px;
      position: relative;
      top: -18.5px;
      left: 90px;
      display: block;
    }
  }
}