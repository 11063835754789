@import 'src/styles';

.container {
  .title {
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize26;
    font-weight: $fwstb;
    padding-bottom: 5px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  .h3_title {
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize26;
    font-weight: $fwstb;
    padding: 25px 0 5px;
    text-align: left;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  .content {
    overflow: hidden;

    &.hidden {
      max-height: 250px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0px;
        height: 100px;
        width: 100%;
        background: #152b35;
        background: linear-gradient(0deg, #152b35 0%, transparent 120%);
      }
    }
  }

  .secondary_title {
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    font-size: $fontSize20;
    font-weight: $fwstb;
    line-height: $fontSize34;
    text-align: left;
    padding: 25px 0 0;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize24;
    }

    &.h2_title {
      font-size: $fontSize26;

      @media (min-width: $desktopWidth) {
        font-size: $fontSize30;
      }
    }
  }

  .text {
    color: rgba($white, 0.7);
    font-size: $fontSize14;
    line-height: $fontSize22;
    padding-top: 10px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize18;
      line-height: $fontSize26;
    }

    &.bold {
      font-weight: $fwstb;
    }
  }
}