@import "src/styles";

.intro {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  padding: 0 0 16px;

  @media (min-width: $desktopWidth) {
    padding-bottom: 20px;
  }

  main,
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $desktopWidth) {
      flex-direction: row;
    }

    .content {
      margin-top: 15px;

      @media (min-width: $desktopWidth) {
        margin-top: 20px;
      }

      .sub_button {
        @media (min-width: $desktopWidth) {
          width: 50%;
        }
      }
    }
  }

  img {
    width: 345px;
    height: 150px;
  }

  .title {
    font-family: 'Philosopher';
    font-weight: $fwstb;
    font-size: $fontSize28;
    white-space: break-spaces;
    padding: 15px;
    color: #F2E6C4;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize32;
    }
  }

  .text {
    font-family: 'Open Sans';
    color: $white;
    opacity: 70%;
    font-size: $fontSize15;
    line-height: $fontSize22;
    text-align: left;
    padding: 0 15px;

    &:nth-child(2) {
      margin-top: 10px;
    }

    @media (min-width: $desktopWidth) {
      font-size: $fontSize20;
      line-height: $fontSize24;
    }
  }
}