@import 'src/styles';

.button {
  border-radius: 30px;
  margin: 30px 0 40px;
  width: 80%;
  background: linear-gradient(2.59deg, #00EEDB -77.77%, #27BAA7 144.46%);
  font-family: 'Philosopher';
  padding: 15px 0;
  text-align: center;
  font-size: $fontSize22;
  color: $white;
  font-weight: $fwstb;
  opacity: 0.5;

  &.active {
    opacity: 1;
  }

  @media (min-width: $desktopWidth) {
    width: 40%;
  }
}