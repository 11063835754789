@import "src/styles/index";

.footer {
  font-size: $fontSize14;
  line-height: $fontSize30;
  color: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  padding: 0 0 30px;
  margin: 0 auto;

  @media (min-width: $desktopWidth) {
    font-size: $fontSize17;
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    .link {
      padding: 0;
    }

    .helpCenter {
      color: #14D5C2;
    }
  }

  .container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
  }

  .link {
    text-decoration: underline;
    color: $white;
    flex-basis: 40%;
    text-align: center;
    padding: 8px 0;
  }

  .address {
    width: 90%;
    text-align: center;
    margin-top: 10px;
  }

  .divider {
    display: flex;
    width: 2px;
    height: 25px;
    background-color: $white;
    margin: 0 8px;
  }
}