@import 'src/styles/index';

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  .result {
    width: 120px;
    height: 120px;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &__img {
      width: 80px;
      height: 80px;
    }

    &__title {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 500;
      font-size: $fontSize12;
      line-height: $fontSize16;
      color: #D0B894;
      position: absolute;
      bottom: 5px;
    }

    &_left {
      background-image: image-set("../../images/result/bg-green-min.webp" 1x, "../../images/result/bg-green2x-min.webp" 2x, "../../images/result/bg-green3x-min.webp" 3x);
    }

    &_right {
      background-image: image-set("../../images/result/bg-purple-min.webp" 1x, "../../images/result/bg-purple2x-min.webp" 2x, "../../images/result/bg-purple3x-min.webp" 3x);
    }
  }

  .element {
    padding: 5px 7px;
    height: 20px;
    background-color: rgba(255, 255, 255, .05);
    border-radius: 50px;
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

    &__title {
      font-family: $fontFamily;
      font-style: normal;
      font-weight: 400;
      font-size: $fontSize12;
      color: #D0B894;
    }

    &__img {
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
}