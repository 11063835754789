@import "src/styles";

.chain_link {
  display: flex;
  flex-direction: column;
  min-height: 150px;
  background: linear-gradient(149.12deg, rgba(25, 206, 187, 0.25) 13.67%, rgba(25, 206, 187, 0.05) 40.81%, rgba(25, 206, 187, 0) 70.82%), #1E3B48;
  margin-top: 15px;
  box-shadow: none;
  position: relative;

  &:nth-child(odd) {
    background: linear-gradient(149.12deg, #3F467C 13.67%, #383E69 40.81%, rgba(56, 62, 105, 0) 70.82%), linear-gradient(0deg, #2C3651, #2C3651), #1E3B48;

    .top_part {
      .border {
        .number {
          background: rgba(132, 96, 255, 0.2);
        }
      }
    }
  }

  .top_part {
    display: flex;
    align-items: center;
    padding-bottom: 10px;

    .border {
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: rgb(35, 255, 232);
      background: linear-gradient(130deg, rgba(35, 255, 232, 1) 10%, rgba(25, 206, 187, 0.1) 22%, rgba(35, 255, 232, 0) 39%, rgba(35, 255, 232, 0) 56%, rgba(197, 131, 250, 0.1) 72%, rgba(184, 97, 255, 1) 100%);

      .number {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba(25, 206, 187, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $fontFamilyPhilosopher;
        font-size: $fontSize20;
        font-weight: $fwstb;
        color: #F2E6C4;
      }
    }

    .title {
      color: #F2E6C4;
      font-family: $fontFamilyPhilosopher;
      font-size: $fontSize20;
      font-weight: $fwstb;
      padding-left: 15px;
      text-align: start;

      @media (min-width: $desktopWidth) {
        font-size: $fontSize24;
      }
    }
  }

  .text {
    color: $white;
    opacity: 70%;
    font-size: $fontSize15;
    line-height: $fontSize22;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize18;
      line-height: $fontSize24;
    }
  }

  &::before {
    content: '';
    width: 3px;
    height: 15px;
    background-color: #2D5668;
    position: absolute;
    top: -15px;
    left: 35px;
  }

  &:nth-child(2) {
    &::before {
      content: '';
      width: 0;
    }
  }

  @media (min-width: $desktopWidth) {
    width: 50%;

    &:nth-child(2) {
      margin-right: 40%;
    }
    &:nth-child(3) {
      margin-right: 20%;
    }
    &:nth-child(4) {
      margin-left: 20%;
    }
    &:nth-child(5) {
      margin-left: 40%;
    }
  }
}