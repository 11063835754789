@import 'src/styles';

.header {
  width: 100vw;
  height: 60px;
  background-color: #1e3b48;

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 400px;

    @media (min-width: $desktopWidth) {
      max-width: $desktopWidth;
    }

    height: 100%;
    position: relative;
    z-index: 2;

    .logo {
      display: flex;
      align-items: center;
    }

    .image {
      width: 40px;
      height: 40px;
      border-radius: 10px;
    }

    .title {
      margin-left: 10px;
      color: #D0B894;
      font-size: $fontSize22;
      font-family: $fontFamilyPhilosopher;
      font-weight: $fwstb;

      @media (min-width: $desktopWidth) {
        font-size: $fontSize26;
      }
    }

    .sign_in {
      background-color: #1e4a54;
      border-radius: 100px;
      position: absolute;
      top: 12px;
      left: 15px;
      font-size: $fontSize16;
      font-weight: $fwb;
      text-decoration: none;
      padding: 8px 12px;
      border: #14D5C2 1px solid;

      a {
        color: $white;
      }
    }
  }
}

.menu {
  @media (min-width: $desktopWidth) {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  a.helpCenter {
    color: #14D5C2;
  }

  .auto_max_width {
    max-width: none;
    pointer-events: none;
  }

}