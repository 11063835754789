@import 'src/styles';

.faq_page {
  padding-top: 15px;
  max-width: $maxWidthDefault;
  margin: 0 auto;

  @media (min-width: $desktopWidth) {
    max-width: 70%;
  }

  header {
    position: relative;
    padding-bottom: 20px;

    .arrow_button {
      padding: 5px 15px 5px;
      position: absolute;
      top: 2px;

      .arrow {
        width: 14px;
        height: 22px;
        fill: #f2e6c4;
      }
    }
  }

  .title {
    font-family: $fontFamilyPhilosopher;
    font-style: normal;
    font-weight: $fwstb;
    font-size: $fontSize26;
    text-align: center;
    color: #F2E6C4;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  .text {
    max-height: 200px;
    font-size: $fontSize14;
    line-height: $fontSize22;
    text-align: left;
    margin-top: 20px;
    margin-right: 20px;
    padding: 0 5px;
    color: $white;
    opacity: 70%;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize18;
      line-height: $fontSize26;
    }
  }

}