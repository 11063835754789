@import "core/styles";

// buttons
$buttonBackground: $primaryColor;

// links
$preloaderColor: $primaryColor;

// titles
$titleH1Color: $primaryColor;
$titleH2Color: $mineShaft;
$titleH2FontSize: $fontSize22;

$desktopWidth: 1000px;
