@import 'src/styles';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  top: -150px;

  @media (min-width: $desktopWidth) {
    top: -194px;
  }

  :global(.swiper-button-prev) {
    transform: scale(-1, 1);
    position: absolute;
    top: 20px;
  }
  :global(.swiper-button-next) {
    position: absolute;
    top: 20px;
  }

  .text {
    color: #D0B894;
    font-size: $fontSize15;
    font-weight: $fwsb;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize18;
    }
  }
}