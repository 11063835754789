@import 'src/styles';

.cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 252px;
  background: linear-gradient(180deg, rgba(21, 43, 53, 0.00) 0%, rgba(21, 43, 53, 0.70) 34.9%, rgba(21, 43, 53, 0.95) 55.56%, #152b35 76.57%, #152b35 100%);
}

.overlay_text {
  white-space: break-spaces;
  color: #F2E6C4;
  text-align: center;
  font-size: $fontSize14;
  font-weight: $fwsb;
  line-height: $fontSize20;
  position: relative;
  top: -10px;
  z-index: 3;

  @media (min-width: $desktopWidth) {
    font-size: $fontSize18;
  }
}