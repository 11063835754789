@import "src/styles";

.wrapper {
  max-width: 420px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media (min-width: $desktopWidth) {
    max-width: 1000px;
    overflow-x: hidden;
  }
}