@import 'src/styles';

.faq {
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;

  .title {
    font-family: $fontFamilyPhilosopher;
    font-style: normal;
    font-weight: $fwstb;
    font-size: $fontSize26;
    text-align: center;
    color: #F2E6C4;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  .text {
    max-height: 200px;
    font-size: $fontSize14;
    line-height: $fontSize22;
    text-align: left;
    margin-top: 20px;
    margin-right: 20px;
    padding: 0 5px;
    color: $white;
    opacity: 70%;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize18;
      line-height: $fontSize26;
    }
  }
}