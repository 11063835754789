@import "src/styles";

.wrapper {
  .tiles {
    @media (min-width: $desktopWidth) {
      display: flex;
      flex-wrap: wrap;
    }
  }

  .title {
    font-size: $fontSize26;
    font-weight: $fwstb;
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    margin-bottom: 20px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }
}