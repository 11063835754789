@import 'src/styles';

.pageContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;

  .content {
    display: flex;
    flex-direction: column;

    a {
      font-size: $fontSize17;
      margin-bottom: 30px;
    }
  }
}