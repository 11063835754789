@import 'src/styles';

div.chapter {
  width: 100%;
  background-color: #1e3b48;
  margin: 20px 0;
  box-shadow: none;
  padding: 18px 16px;

  &:last-child {
    margin: 20px 0 0;
  }

  @media (min-width: $desktopWidth) {
    max-width: 45%;
    justify-content: space-around;

    &:last-child {
      margin: 20px 0;
    }
  }

  .wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .img {
      width: 105px;
      height: 105px;
    }

    .content {
      width: 65%;
      display: flex;
      flex-direction: column;

      .title {
        font-family: $fontFamilyPhilosopher;
        text-align: left;
        padding-bottom: 10px;
        font-size: $fontSize20;
        font-weight: $fwstb;
        color: #F2E6C4;

        @media (min-width: $desktopWidth) {
          font-size: $fontSize24;
        }
      }

      .text {
        text-align: left;
        color: $white;
        opacity: 70%;
        font-size: $fontSize15;
        line-height: $fontSize22;

        @media (min-width: $desktopWidth) {
          font-size: $fontSize18;
          line-height: $fontSize24;
        }
      }

      .link {
        align-self: flex-end;
        padding: 0px 20px 0;

        &::before {
          content: '';
          transform: rotate(-90deg);
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0' fill='%23D8D8D8' d='M19 0v19H0V0z'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='m9.821 12.38 4.046-3.675a.387.387 0 0 0 0-.584.486.486 0 0 0-.642 0L9.5 11.505 5.775 8.12a.487.487 0 0 0-.642 0 .388.388 0 0 0 0 .585l4.046 3.675a.492.492 0 0 0 .642 0z' fill='%232D2D2D' stroke='%2314D5C2' stroke-width='.88'/%3E%3C/svg%3E%0A");
          width: 20px;
          height: 20px;
          position: relative;
          top: 18.5px;
          right: -35px;
          display: block;

          @media (min-width: $desktopWidth) {
            right: -40px;
          }
        }
      }
    }
  }
}