@import "src/styles";

.wrapper {
  flex-direction: column;
  align-items: center;
  padding-bottom: 0;

  .title {
    font-size: $fontSize26;
    font-weight: $fwstb;
    color: #F2E6C4;
    font-family: $fontFamilyPhilosopher;
    padding-bottom: 5px;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize30;
    }
  }

  .image {
    margin: 0 auto;
    width: 215px;
    height: 290px;
  }

  .lines {
    margin: 0 auto;

    .line {
      display: flex;
      margin-bottom: 10px;

      .dot {
        width: 8px;
        height: 8px;
        background-color: $white;
        border-radius: 50%;
        margin-right: 10px;
      }

      .text {
        width: 97%;
        color: rgba(255, 255, 255, 0.70);
        font-size: $fontSize15;
        line-height: $fontSize22;
        font-weight: $fwn;
        margin-top: -6px;

        @media (min-width: $desktopWidth) {
          font-size: $fontSize18;
        }

        b {
          font-weight: $fwb;
          padding-right: 4px;
        }

      }
    }
  }
}