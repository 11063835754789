@import 'src/styles';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #1e3b48;
  box-shadow: none;
  margin-top: 15px;

  @media (min-width: $desktopWidth) {
    width: 30%;
  }

  .header {
    display: flex;

    .image {
      width: 45px;
      height: 45px;
    }

    .stars {
      padding-left: 10px;

      .star {
        width: 96px;
        height: 33px;
      }

      .title {
        color: $white;
        opacity: 80%;
        padding-left: 10px;

        @media (min-width: $desktopWidth) {
          font-size: $fontSize20;
        }
      }
    }
  }

  .text {
    color: $white;
    line-height: $fontSize20;
    font-size: $fontSize14;

    @media (min-width: $desktopWidth) {
      font-size: $fontSize15;
      line-height: $fontSize22;
    }
  }
}